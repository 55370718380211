.ant-picker.ant-picker-range {
  height: 60px;
  padding: 18px 12px;
}

input::placeholder,
.ant-picker-input input {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #9f9f9f !important;
}

.ant-picker-input input {
  color: #131313 !important;
}

@media (max-width: 992px) {
  .ant-picker.ant-picker-range {
    padding: 12px !important;
    height: 48px;
  }
}

@media (max-width: 576px) {
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
    min-width: unset !important;
  }

  .ant-picker-panels {
    flex-direction: column;
  }

  input::placeholder,
  .ant-picker-input input {
    font-size: 16px;
    line-height: 22px;
  }
}
