.ant-select {
  height: 60px;
}

.ant-select-selector {
  padding: 16px 12px !important;
  height: 100% !important;
}

.ant-select-selection-placeholder,
.ant-select-selection-item {
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  color: #131313 !important;
}

@media (max-width: 992px) {
  .ant-select {
    height: 48px;
  }

  .ant-select-selector {
    padding: 12px !important;
  }
}

@media (max-width: 576px) {
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}
